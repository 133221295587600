/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/16/2020
 * @Example
 */
import React                         from "react";
import PropTypes                     from "prop-types";
import {FormGroup, Col, Row}         from "react-bootstrap";
import Config                        from "../../../../../config";
import {Checkbox, Combo}             from "../../../../common/form-material";
import {bindActionCreators, compose} from "redux";
import {connect}                     from "react-redux";
import * as W25F2011Actions          from "../../../../../redux/W2X/W25F2011/W25F2011_actions";
import {FormControl}                 from "@material-ui/core";
import Modal                         from "../../../../common/modal/modal";
import ButtonGeneral                 from "../../../../common/button/button-general";
import {TextBox}                     from "devextreme-react";
import MForm                         from "../../../../common/form-material/form";
import _                             from "lodash";

class W25F2011AddStage extends React.PureComponent {
    constructor(props) {
        super(props);
        const data = props && props.rowData ? props.rowData : null;
        this.state = {
            StageID: data && data.StageID ? data.StageID : "",
            StageName: data && data.StageName ? data.StageName : "",
            IsAutoSendmail: data && data.IsAutoSendmail ? data.IsAutoSendmail : 0,
            TemplateMailID: data && data.TemplateMailID ? data.TemplateMailID : "",
            error: {},
            loading: false,
            mode: "add"
        };
        this.StageName = null;
        this.isSaved = false;
    }

    componentDidMount () {
        // this.loadCboMailTemplate();
        const {mode, rowData: data} = this.props;
        this.setState({
            StageID: data && data.StageID ? data.StageID : "",
            StageName: data && data.StageName ? data.StageName : "",
            IsAutoSendmail: data && data.IsAutoSendmail ? data.IsAutoSendmail : 0,
            TemplateMailID: data && data.TemplateMailID ? data.TemplateMailID : "",
            mode: mode
        });
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.rowData) !== JSON.stringify(this.props.rowData)) {
            const data = this.props.rowData;
            this.setState({
                StageID: data && data.StageID ? data.StageID : "",
                StageName: data && data.StageName ? data.StageName : "",
                IsAutoSendmail: data && data.IsAutoSendmail ? data.IsAutoSendmail : 0,
                TemplateMailID: data && data.TemplateMailID ? data.TemplateMailID : "",
                mode: this.props.mode
            });
        }
    }

    setStateErrorText = (value) => {
        const {error} = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    onSave = (isContinue) => {
        const {RecInfoID} = this.props.dataForm;
        const {mode, StageID, StageName, IsAutoSendmail, TemplateMailID} = this.state;
        const validateRules = [
            {
                name: "StageName",
                rules: "isRequired",
                value: StageName
            }
        ];
        // if (!IsAutoSendmail) {
        //     validateRules.push({
        //         name: "TemplateMailID",
        //         rules: "isRequired",
        //         value: TemplateMailID
        //     });
        // }
        const validateForm = MForm.formValidation(validateRules);
        if (this.setStateErrorText(validateForm)) return false;
        let params = {
            RecInfoID: RecInfoID || "",
            FormID: "W25F2011",
            StageName: StageName ? StageName : "",
            IsAutoSendmail: IsAutoSendmail ? Number(IsAutoSendmail) : 0,
            TemplateMailID: TemplateMailID ? TemplateMailID : ""
        };
        if (mode === "edit") {
            params.StageID = StageID;
        }
        this.setState({loading: true});
        this.props.W25F2011Actions[mode === "edit" ? "updateStages" : "saveStages"](params, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.isSaved = true;
            if (isContinue) {
                Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                this.resetForm();
            } else {
                Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                this.onClose();
            }
        });
    };

    resetForm = () => {
        this.setState({
            StageID: "",
            StageName: "",
            IsAutoSentmail: 0,
            EmailTemplate: "",
            mode: "add",
            error: {}
        }, () => {
            if (this.StageName) this.StageName.instance.option('value', "");
        });
    };

    onClose = () => {
        const {onClose} = this.props;
        this.resetForm();
        if (onClose) onClose(null, null, this.isSaved);
    };

    handleChange = (key, e) => {
        if (!key || !e) return false;
        if (key !== "IsAutoSendmail") {
            const value = _.get(e, "value", _.get(e, "target.value", ""));
            this.setState({[key]: value});
        } else {
            this.setState({[key]: e.target.checked ? 1 :0}, () => {
                this.setState({TemplateMailID: ""});
            });
        }
    };

    render() {
        const { open, getCboMailTemplate } = this.props;
        const {loading, error, IsAutoSendmail, StageName, TemplateMailID} = this.state;

        return (
            <React.Fragment>
                <Modal open={open} maxWidth={"sm"} fullWidth={true}
                    title={Config.lang("Them_giai_doan")}
                    onClose={this.onClose}
                >
                    <Modal.Content>
                        <div className={""}>
                            <FormGroup style={{marginBottom: 5}}>
                                <Row className={"display_row align-center"}>
                                    <Col xs={12} sm={4} md={4} lg={4}>
                                        <span className={"mgb0"}>{Config.lang('Ten_giai_doan')}&nbsp;<span style={{color: "red"}}>*</span></span>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <FormControl component={"div"}
                                                     variant={'standard'}
                                                     margin={"normal"}
                                                     fullWidth={true}
                                        >
                                            <TextBox
                                                isValid={!(error && error['StageName'])}
                                                placeholder={Config.lang('Nhap')}
                                                ref={ref => this.StageName = ref}
                                                required
                                                disabled={loading}
                                                // inputProps={{
                                                //     readOnly: true
                                                // }}
                                                defaultValue={StageName || ""}
                                                onValueChanged={(e) => this.handleChange('StageName', e)}
                                            />
                                        </FormControl>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"mgb0"}>
                                <Checkbox
                                    checked={!!IsAutoSendmail}
                                    size={"medium"}
                                    label={Config.lang("Chon_lai_mau_email_truoc_khi_gui")}
                                    disabled={loading}
                                    style={{marginLeft: -12}}
                                    color={"primary"}
                                    onChange={(e) => this.handleChange('IsAutoSendmail', e)}
                                />
                            </FormGroup>
                            <FormGroup style={{marginBottom: 5}}>
                                {!IsAutoSendmail && <Row className={"display_row align-center"}>
                                    <Col xs={12} sm={4} md={4} lg={4}>
                                        <span className={"mgb0"}>{Config.lang('Email_tu_dong')}</span>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <Combo
                                            error={error && error['TemplateMailID']}
                                            dataSource={getCboMailTemplate}
                                            displayExpr={'TemplateMailName'}
                                            valueExpr={'TemplateMailID'}
                                            margin={"normal"}
                                            disabled={loading}
                                            value={TemplateMailID || null}
                                            stylingMode={'underlined'}
                                            placeholder={Config.lang('Chon')}
                                            onValueChanged={(e) => this.handleChange("TemplateMailID", e)}
                                        />
                                    </Col>
                                </Row>}
                            </FormGroup>
                            <FormGroup className={"display_row align_center valign-bottom"}>
                                <ButtonGeneral name={Config.lang('Luu')}
                                               typeButton={'save'}
                                               color={"primary"}
                                               className={"mgr5"}
                                               variant={"outlined"}
                                               style={{textTransform: 'uppercase'}}
                                               size={"large"}
                                               disabled={loading}
                                               onClick={this.onSave}/>
                                <ButtonGeneral name={Config.lang('Luu_va_nhap_tiep')}
                                               typeButton={'save'}
                                               style={{textTransform: 'uppercase'}}
                                               size={"large"}
                                               disabled={loading}
                                               onClick={() => this.onSave(true)}/>
                            </FormGroup>
                        </div>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        );
    }

}

W25F2011AddStage.propTypes = {
    open: PropTypes.bool,
    rowData: PropTypes.object,
    dataForm: PropTypes.object,

    onClose: PropTypes.func,
};

export default compose(connect((state) => ({
    getCboMailTemplate: state.W25F2011.getCboMailTemplate,
}), (dispatch) => ({
    W25F2011Actions: bindActionCreators(W25F2011Actions, dispatch),
})))(W25F2011AddStage);