/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/16/2020
 * @Example
 */
import React                         from "react";
import PropTypes                     from "prop-types";
import {FormGroup, Col, Row}         from "react-bootstrap";
import Config                        from "../../../../../config";
import {bindActionCreators, compose} from "redux";
import {connect}                     from "react-redux";
import * as W25F2011Actions          from "../../../../../redux/W2X/W25F2011/W25F2011_actions";
import * as generalActions           from "../../../../../redux/general/general_actions";
import withStyles                    from "@material-ui/core/styles/withStyles";
import DateBoxPicker                 from "../../../../common/form-material/date-box";

const styles = {
    textBox: {
        width: 'calc(100% - 32px)'
    },
};

class W25F2011Expand4 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleChange = (key, e) => {
        if (!key || !e) return false;
        const {onChange} = this.props;
        if (onChange) onChange(key, e);
    };

    render() {
        const { error, dataForm, onValidate } = this.props;
        const minDate = dataForm ?
            (dataForm.ReceiveDateTo ? dataForm.ReceiveDateTo :
                (dataForm.ReceiveDateFrom ? dataForm.ReceiveDateFrom : null)) :
                null;

        return (
            <div style={{width: "100%"}}>
                <Row>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <FormGroup className={"mgt15"}>
                            <DateBoxPicker
                                placeholder={Config.lang("DHR_Chon_ngay")}
                                useMaskBehavior={true}
                                showClearButton={true}
                                width={"70%"}
                                styleForm={{marginTop: 4}}
                                stylingMode={"underlined"}
                                shrink={true}
                                value={dataForm && dataForm.Deadline}
                                onValueChanged={e => this.handleChange("Deadline", e)}
                                margin={"normal"}
                                min={minDate}
                                // disabled={disabled || loading}
                                error={error && error["Deadline"]}
                                dateBoxProps={{
                                    onFocusOut: (e) => onValidate && onValidate("Deadline", e)
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }

}

W25F2011Expand4.propTypes = {
    data: PropTypes.object
};

export default compose(connect((state) => ({
    getColumns: state.W25F2011.getColumns,
    getCboColumns: state.W25F2011.getCboColumns,
    getHourDecimal: state.W25F2011.getHourDecimal,
    getMaster: state.W25F2011.getMaster,
    getGridMaster: state.W25F2011.getGridMaster,
}), (dispatch) => ({
    W25F2011Actions: bindActionCreators(W25F2011Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(W25F2011Expand4);