/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/16/2020
 * @Example
 */
import React                 from "react";
import PropTypes             from "prop-types";
import {FormGroup, Col, Row} from "react-bootstrap";
import Config                from "../../../../../config";
import {Combo}               from "../../../../common/form-material";
import withStyles            from "@material-ui/core/styles/withStyles";
import Typography            from "@material-ui/core/Typography";
import {TextBox}             from "devextreme-react";
import UserImage             from "../../../../common/user/user-image";
import moment                from "moment";
import PopupSelect           from "../../../../popup/popup-select";

const styles = {
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        position: 'relative',
        minHeight: 165,
        border: '1px solid #687799',
        paddingBottom: 20
    },
    border: {
        borderImageWidth: 'initial',
        borderImageOutset: 'initial',
        borderImageSource: 'url(https://aui-cdn.atlassian.com/media/border-icon.png)',
        borderImageSlice: 2,
        borderImageRepeat: 'round',
        height: '100%',
        width: '100%',
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: 'rgb(207, 212, 219)',
        borderRadius: 3,
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& .text-muted': {
            color: 'rgb(167, 167, 167)',
        }
    },
    dropzone: {
        position: 'absolute',
        // top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 98,
        cursor: 'pointer',
        border: "1px solid #EBECF2"
    },
    dropzoneActive: {
        backgroundColor: "#eaeaeabf",
        zIndex: 2000,
    },
    bgNone: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "#EBECF2"
    },
    iconAdd: {
        marginBottom: 10,
    },
    heading: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    textBox: {
        width: 'calc(100% - 32px)'
    },
    borderCboEmployee: {
        borderBottom: "1px solid #687799"
    },
};

class W25F2011Expand3 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showPopupListMember: false
        };
        this.popupListMember = null;
    }

    renderFieldInterviewer = (data) => {
        const {classes} = this.props;
        return (
            <div className={"display_row align-right"} style={{width: "100%", height: '100%', padding: "5px 0"}}>
                {data && data.map((d, idx) => {
                    return <div key={idx}>
                        <UserImage data={d} width={32}
                                   height={32} keyExpr={"UserID"}
                                   valueExpr={"UserID"}
                                   renderItemHover={user => {
                                       const dateJoined = user ? moment(user.DateJoined).isValid() ? moment(user.DateJoined).format('DD/MM/YYYY') : user.DateJoined : "";
                                       return (
                                           <div className={""}>
                                               {user && user.EmployeeID && <span><b>{user.EmployeeID + " - " + user.EmployeeName}</b><br/></span>}
                                               {user && user.DepartmentName && <span>{Config.lang('DHR_Phong_ban') + ': ' + user.DepartmentName}<br/></span>}
                                               {user && user.ProjectName && <span>{Config.lang('DHR_Du_an') + ': ' + user.ProjectName}<br/></span>}
                                               {user && user.DutyName && <span>{Config.lang('DHR_Chuc_vu') + ': ' +user.DutyName}<br/></span>}
                                               {user && user.DateJoined && <span>{Config.lang('DHR_Ngay_vao_lam') + ': ' + dateJoined}</span>}
                                           </div>
                                       );
                                   }}
                        />
                    </div>
                })}
                <TextBox className={classes.textBox}
                         defaultValue={""}
                         placeholder={data && data.length < 1 ? Config.lang('Nguoi_quan_ly') : ""}
                         readOnly={true}
                         // onKeyDown={this.onTextBoxKeyDown}
                />
            </div>
        );
    };

    renderInterviewer = (data) => {
        if (data) {
            return (
                <div className={"display_row align-center"}>
                    <UserImage data={data} keyExpr={"UserID"} valueExpr={"UserID"} width={32} height={32} />
                    <div className="cbo-employee-name">
                        {data.EmployeeName || ""}
                    </div>
                </div>
            );
        }
    };

    onTextBoxKeyDown = (e) => {
        if (!e || !e.event || !e.event.target) return false;
        const evt = e.event;
        const {dataForm} = this.props;
        let ListMember = dataForm && dataForm.ListMember ? dataForm.ListMember : "";
        ListMember = ListMember
            ? (Array.isArray(ListMember) ? ListMember : dataForm.ListMember.replace(/,/g, ';').split(';'))
            : [];
        if (evt.keyCode === 8 && !evt.target.value) {
            ListMember.pop();
            setTimeout(() => {
                this.handleChange("ListMember", {value: ListMember});
            }, 300);
        }
    };

    handleChange = (key, e) => {
        if (!key || !e) return false;
        const {onChange} = this.props;
        if (onChange) onChange(key, e);
    };

    render() {
        const { classes, error, dataForm, onValidate } = this.props;
        const { showPopupListMember } = this.state;
        let ListMember = dataForm && dataForm.ListMember ? dataForm.ListMember : "";
        ListMember = ListMember
            ? (Array.isArray(ListMember) ? ListMember : dataForm.ListMember.replace(/,/g, ';').split(';'))
            : [];
        const listUsers = Config.listUsers && Config.listUsers.filter(l => !!l.UserID);
        const columnEmployee = [
            {
                caption: Config.lang("Ma"),
                dataField: "EmployeeID"
            },
            {
                caption: Config.lang("Ten"),
                    dataField: "EmployeeName"
            }
        ];

        return (
            <div style={{width: "100%"}}>
                <Row>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <FormGroup className={"mgt15"}>
                            <fieldset>
                                <Typography style={{marginBottom: 10}} className={classes.heading}>{Config.lang("DHR_Thanh_vien_quan_ly")}&nbsp;<span style={{color: "red"}}>*</span></Typography>
                                <Typography style={{marginBottom: 10}} className={"text-muted"} variant={"body2"}>
                                    {Config.lang("DHR_Nhung_nguoi_co_quyen_xem_toan_bo_TTUV_va_xu_ly_quy_trinh_tuyen_dung")}
                                </Typography>
                                <PopupSelect
                                    ref={ref => this.popupListMember = ref}
                                    typePaging={"normal"}
                                    mode={'multiple'}
                                    keyExpr={"UserID"}
                                    title={Config.lang("Nguoi_quan_ly")}
                                    open={showPopupListMember}
                                    dataSource={listUsers || []}
                                    column={columnEmployee}
                                    allowFilterSelected
                                    onSave={(data) => this.handleChange("ListMember", {value: data})}
                                />
                                <Combo
                                    error={error && error["ListMember"]}
                                    dataSource={Config.storeDataSoureDevExtreme(listUsers || [])}
                                    displayExpr={"EmployeeName"}
                                    valueExpr={'UserID'}
                                    value={ListMember}
                                    multiple={true}
                                    shrink={true}
                                    style={{width: "70%"}}
                                    height={37}
                                    stylingMode={'underlined'}
                                    margin={"dense"}
                                    className={classes.borderCboEmployee}
                                    acceptCustomValue={false}
                                    fieldRender={this.renderFieldInterviewer}
                                    itemRender={this.renderInterviewer}
                                    placeholder={Config.lang('Thanh_vien_quan_ly')}
                                    selectProps={{
                                        // readOnly: true,
                                        onFocusIn: () => {
                                            this.setState({showPopupListMember : true}, () => {
                                                if(this.popupListMember) {
                                                    this.popupListMember.open(ListMember)
                                                }
                                            })
                                        },
                                        onFocusOut: (e) => onValidate && onValidate("ListMember", e)
                                    }}
                                    openOnFieldClick={false}
                                    showDropDownButton={false}
                                    // selectProps={{
                                    //     onFocusOut: (e) => onValidate && onValidate("ListMember", e),
                                    // }}
                                    // onValueChanged={(e) => this.handleChange("ListMember", e)}
                                />
                            </fieldset>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }

}

W25F2011Expand3.propTypes = {
    error: PropTypes.any,
    dataForm: PropTypes.object,
    onChange: PropTypes.func,
};

export default withStyles(styles)(W25F2011Expand3);