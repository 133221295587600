/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 09/21/2020
 * @Example
 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as W25F2015Actions from "../../../../redux/W2X/W25F2015/W25F2015_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import DropContext from "./utils/DropContext";
import PropTypes from "prop-types";
class W25F2015WorkFlow extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            columns: {},
            showDetail: "",
            dataTemp: {},
        };
        this.getColumns = null;
        this.dataTemp = null;
    }

    loadDetail = taskID => {
        // console.log("=============loadDetail=============",taskID);
        this.setState({ showDetail: taskID });
    };

    editTask = (params, cb) => {
        const { updateStage } = this.props;
        this.props.W25F2015Actions.updateStage(params, (error, data) => {
            cb && cb(error, data);
            if (data) {
                this.dataTemp = this.getColumns;
                updateStage(this.getColumns);
            }
        });
    };

    // componentDidUpdate(prevProps) {
    //     // console.log(prevProps.dataTemp, "============>", this.dataTemp);
    //     if (JSON.stringify(this.dataTemp) !== JSON.stringify(prevProps.dataTemp)) {
    //         this.props.updateStage(this.dataTemp);
    //     }
    // }
    loadColumn = () => {
        const { dataListCandidate } = this.props;
        if (dataListCandidate.length > 0) {
            dataListCandidate.forEach((item, idx) => {
                if (!this.dataTemp.tasks[item.CandidateID]) {
                    this.dataTemp.tasks[item.CandidateID] = item;
                    if (this.dataTemp.columns[item.StageID]) {
                        this.dataTemp.columns[item.StageID].taskIds.push(item.CandidateID);
                    }
                }
            });
            this.setState({ dataTemp: this.dataTemp });
            this.props.updateStage(this.dataTemp);
        }
    };
    componentDidMount() {
        this.loadColumn();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.dataListCandidate) !== JSON.stringify(prevProps.dataListCandidate)) {
            this.loadColumn();
        }
    }

    render() {
        const { dataListCandidate, openW25F2040Popup } = this.props;
        const { dataTemp } = this.state;
        let dataStage = this.props.dataStage;
        dataStage.sort((a, b) => a.StageOrderNo < b.StageOrderNo);

        let columns = {};
        dataStage.forEach((rs, index) => {
            columns[`${rs.StageID}`] = {
                id: rs.StageID,
                title: rs.StageName,
                taskIds: [],
                // backgroundColor: '#DA695B',
                code: `CO${index + 1}`,
                order: rs.StageOrderNo,
                IsAutoSendmail: rs.IsAutoSendmail ?? 0,
                EmailTemplate: rs.EmailTemplate ?? '',
            }
        })
        const arrayOrder = dataStage.map(rs => rs.StageID);
        let dataTempInit = {
            tasks: {},
            columns: columns,
            columnOrder: arrayOrder,
        };

        this.dataTemp = dataTempInit;
        return (
            <React.Fragment>
                {dataListCandidate && dataTemp && (
                    <DropContext
                        getColumns={val => (this.getColumns = val)}
                        editTask={this.editTask}
                        data={dataTemp}
                        loadDetail={this.loadDetail}
                        dataStageLength={dataStage.length}
                        openW25F2040Popup={openW25F2040Popup}
                    />
                )}
            </React.Fragment>
        );
    }
}

W25F2015WorkFlow.propTypes = {
    dataListCandidate: PropTypes.array,
    dataStage: PropTypes.array,
};

export default compose(
    connect(
        state => ({}),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W25F2015Actions: bindActionCreators(W25F2015Actions, dispatch),
        })
    )
)(W25F2015WorkFlow);
