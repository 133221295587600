/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 09/21/2020
 */

import React from "react";
import ItemTemplate from "./ItemTemplate";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { browserHistory } from "react-router";
import Config from "../../../../../config";
import PropTypes from 'prop-types';

const TaskContainer = styled.div`
            margin: 5px 5px 5px 5px;
            padding: 15px;
            height: 128px;
            background-color: ${props => props.isDragging ? '#FFFFFF' : 'white'};
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            &:hover {
                box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
              }
        `;

class Task extends React.PureComponent {

    loadDetail = (taskID) => {
        if (this.props.loadDetail) this.props.loadDetail(taskID);
    };
    goToPage = () => {
        const { task } = this.props;
        browserHistory.push({
            pathname: Config.getRootPath() + "W25F2023",
            state: {
                CandidateID: task.CandidateID || "",
                RecInfoID: task.RecInfoID || "",
                InterviewFileID: task.InterviewFileID || "",
                InterviewTitle: task.InterviewTitle || "",
            }
        });
    }
    render() {
        const { task, index } = this.props;
        return (
            <Draggable key={task.CandidateID}
                       draggableId={task.CandidateID}
                       isDragDisabled={!!task.IsMoved || !!task.IsEmployee || (task.ApproveLevel > 1 && task.ApprovalStatus === 0)}
                       index={index}>
                {(provided, snapshot) => (
                    <TaskContainer {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        // className=" align-between"
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                    >
                        <ItemTemplate
                            // dataInfoCandidate={dataInfoCandidate}
                            data={task}
                            onClick={() => this.goToPage()}
                        />
                    </TaskContainer>
                )}
            </Draggable>
        )
    }
}

Task.propTypes = {
    task: PropTypes.object,
    index: PropTypes.number,
};

export default Task;