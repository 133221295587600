/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 09/21/2020
 */

import React from "react";

import { Droppable } from "react-beautiful-dnd";
import Task from "./Task";
import "./w25F2015.scss"
import PropTypes from "prop-types";
import {Tooltip} from "@material-ui/core";

class ColumnItem extends React.PureComponent {



    loadDetail = (taskID) => {
        if (this.props.loadDetail) this.props.loadDetail(taskID);
    };

    render() {
        const { column, tasks, isDropable, dataStageLength, index, formID } = this.props;
        let maximum = formID === "W25F2016" ? dataStageLength : dataStageLength - 1;
        return (
            <div className="list-column"
                style={{ minWidth: `calc(100% / ${dataStageLength}` }}
                ref='list-column'>
                <div
                    className="list-header"
                    style={{
                        backgroundColor: column.backgroundColor,
                        height: 40
                    }}>
                    <span className="tasks-header" style={{ paddingLeft: 5, color: column.id === "REJECTED" ? "#FF3D71" : "" }}>
                            {tasks && tasks.length > 0 ? tasks.length : ""}
                    </span>
                    <Tooltip title={column && column.title ? column.title : ""}>
                        <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 10}} className="title-header">
                            {column && column.title ? column.title : ""}
                        </span>
                    </Tooltip>
                    {
                        index !== maximum ?
                            index === maximum - 1 ?
                                <div style={{ position: "absolute", right: 0, borderRight: "2px solid #7F828E", height: 16 }} /> :
                                <i className="fas fa-chevron-right" style={{ position: "absolute", right: 0, color: "#7F828E" }} />
                            : null
                    }
                </div>
                {isDropable && (<Droppable droppableId={column.id}
                    type="COLUMN"
                    ignoreContainerClipping={true}
                // type={column.id === 'column-1' ? 'done' : 'active'}
                >
                    {(provided, snapshot) => {
                        return (
                            <div style={{ backgroundColor: "#fff", paddingLeft: 5, paddingTop: 5, height: "calc(100vh - 214px)", }}>
                                <div className="display_col overflow-hover"
                                    style={{
                                        height: "100%",
                                        minWidth: 290,
                                        overflow: "hidden",
                                        backgroundColor: snapshot.isDraggingOver ? "#D8D9DD" : '#F5F6F6'
                                    }}
                                    ref={provided.innerRef}
                                    // isDraggingOver={snapshot.isDraggingOver}
                                    {...provided.droppableProps}
                                >

                                    {tasks.map((task, index) => {
                                        return <Task
                                            // dataInfoCandidate={findData}
                                            key={task.CandidateID}
                                            task={task}
                                            index={index}
                                            loadDetail={this.loadDetail}
                                        />
                                    })}
                                    {provided.placeholder}
                                </div>
                            </div>
                        )
                    }}
                </Droppable>)}
            </div>
        );
    }
}
ColumnItem.propTypes = {
    column: PropTypes.any,
    tasks: PropTypes.array,
    isDropable: PropTypes.bool,
    dataStageLength: PropTypes.number,
    index: PropTypes.number,
    formID: PropTypes.string,
};

export default ColumnItem;