import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Slider, withStyles } from "@material-ui/core";
const styles = theme => ({
    green: {
        backgroundColor: "#00C875",
        borderRadius: "50%",
        width: 8,
        height: 8,
        color: "#00C875",
        "& path, & circle, & line": {
            fill: "#00C875"
        },
    },
    grey: {
        backgroundColor: "#9597A1",
        borderRadius: "50%",
        width: 8,
        height: 8,
        color: "#9597A1",
        "& path, & circle, & line": {
            fill: "#9597A1",
        },
    },
    divText: {
        fontWeight: "bold",
        fontSize: 14,
    },
    slider: {
        display: "flex",
        alignItems: "center",
        "& .MuiSlider-mark": {
            height: 8,
            width: 8,
            borderRadius: "50%",
            backgroundColor: "#9597A1",
            transition: "0.4s",
            "&:hover": {
                transform: "scale(1.2)",
                transition: "fill 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            },
        },
        "& .MuiSlider-markActive": {
            opacity: 1,
            backgroundColor: "#00C875",
            borderRadius: "50%",
            transition: "0.4s",
        },
        "& .MuiSlider-track": {
            height: 1,
            backgroundColor: "#00C875",
        },
        "& .MuiSlider-rail": {
            height: 1,
        },
        "& .MuiSlider-thumb": {
            display: "none",
        },
    },
})
class Step extends PureComponent {

    render() {
        const { classes, dataStage, StageID } = this.props;
        // if (StageID === "REJECTED") return false;
        dataStage.sort((a, b) => a.StageOrderNo < b.StageOrderNo);
        let arr = dataStage.filter(({ StageID }) => (StageID !== "REJECTED")).map(({ StageID }) => { return StageID });
        const findStageID = arr.indexOf(StageID);
        const findStageName = dataStage.find(item => item.StageID === StageID);
        let arrIcon = [];
        let stageValue = (100 * findStageID / (arr.length - 1))
        arr.forEach((item, index) => {
            let value = (100 * index / (arr.length - 1));
            arrIcon.push({ value })
        })
        return (
            <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
                <div className={classes.divText}>
                    {findStageName?.StageName}
                </div>
                <div className="display_row align-center " style={{ flexDirection: "row", width: "95%" }}>
                    {<Slider
                        className={classes.slider}
                        // step={33}
                        value={stageValue}
                        marks={arrIcon}
                    />}
                </div>
            </div>
        );
    }
}

Step.propTypes = {
    dataStage: PropTypes.array,
    StageID: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(Step);