import { Editor } from '@tinymce/tinymce-react';
import { LoadPanel } from 'devextreme-react';
import { Button, Dropdown, Label, Modal, ModalBody, ModalFooter, ModalHeader, TextInput } from 'diginet-core-ui/components';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Config from '../../../../../config';
import * as W25F2015Actions from '../../../../../redux/W2X/W25F2015/W25F2015_actions';

const SendMailPop = (props) => {
    const { open, handleOpenPopup, currentStage } = props;

    const [loading, setLoading] = useState(false);
    const [dataCboMailTemplate, setDataCboMailTemplate] = useState([]);
    const [selectedMailID, setSelectedMailID] = useState('');
    const [titleMail, setTitleMail] = useState('');
    const [contentMail, setContentMail] = useState('');
    const [mailData, setMailData] = useState({});

    const typingRef = useRef(null);

    const dispatch = useDispatch();

    const getCboMailTemplate = () => {
        setLoading(true);
        dispatch(
            W25F2015Actions.getCboMailTemplate({}, (error, data) => {
                setLoading(false);
                if (error) {
                    const message = error?.message ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup2.show('info', message);
                }
                if (data) {
                    setDataCboMailTemplate(data);
                }
            })
        );
    };

    const loadMailData = () => {
        const params = {
            Language: Config?.language ?? '84',
            FormID: 'W25F2016',
            RecInfoID: currentStage?.RecInfoID ?? '',
            CandidateID: currentStage?.CandidateID ?? '',
            StageID: currentStage?.newStageID ?? '',
        };
        setLoading(true);
        dispatch(
            W25F2015Actions.loadMailData(params, (err, dt) => {
                setLoading(false);
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    Config.popup2.show('info', msg);
                }
                if (dt) {
                    setMailData(dt);
                }
            })
        );
    };

    useEffect(() => {
        getCboMailTemplate();
        loadMailData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (name, e) => {
        switch (name) {
            case 'mailTemplate':
                const selectedMailID = e?.value ?? '';
                setSelectedMailID(selectedMailID);
                let titleMail = e?.data?.TitleMail ?? '';
                let contentMail = e?.data?.ContentMail ?? '';
                if (selectedMailID) {
                    titleMail = handleMail(titleMail);
                    contentMail = handleMail(contentMail);
                }
                setTitleMail(titleMail);
                setContentMail(contentMail);
                break;
            case 'titleMail':
                if (typingRef.current) {
                    clearTimeout(typingRef.current);
                }
                const title = e?.target?.value ?? '';
                typingRef.current = setTimeout(() => {
                    setTitleMail(title);
                }, 500);
                break;
            case 'contentMail':
                setContentMail(e ?? '');
                break;
            default:
                break;
        }
    };

    const handleMail = (data) => {
        let newData = data;
        const mData = mailData?.MailData ?? {};
        if (newData.length > 0 && Object.keys(mData).length > 0) {
            Object.keys(mData).forEach((i) => {
                newData = newData.replace(`&lt;%- ${i} %&gt;`, mData?.[i] ?? '');
            });
        }
        return newData;
    };

    const sendMail = () => {
        const params = {
            Language: Config?.language ?? '84',
            FormID: 'W25F2015',
            TemplateMailID: selectedMailID ?? '',
            RecInfoID: currentStage?.RecInfoID ?? '',
            CandidateID: currentStage?.CandidateID ?? '',
            StageID: currentStage?.newStageID ?? '',
            TitleMail: titleMail ?? '',
            ContentMail: contentMail ?? '',
        };
        setLoading(true);
        dispatch(
            W25F2015Actions.sendMail(params, (err, dt) => {
                setLoading(false);
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    Config.popup2.show('info', msg);
                }
                if (dt) {
                    Config.notify.show('success', Config.lang('Gui_mail_thanh_cong'), 2000);
                    handleOpenPopup('sendMail', false, true);
                }
            })
        );
    };

    return (
        <>
            <LoadPanel
                shadingColor={'rgba(0,0,0,0.4)'}
                position={{ my: 'center', of: '#W25F2015-modal-send-mail' }}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
            {open && <Modal id={'W25F2015-modal-send-mail'} open={open} width={'50%'} onClose={(e) => handleOpenPopup('sendMail')} zIndex={1300} style={{ minWidth: 250 }}>
                <ModalHeader>{Config.lang('Gui_mail')}</ModalHeader>
                <ModalBody>
                    <Dropdown
                        dataSource={dataCboMailTemplate}
                        displayExpr={'TemplateMailName'}
                        valueExpr={'TemplateMailID'}
                        label={Config.lang('Mau_mail_co_san')}
                        clearAble
                        onChange={(e) => handleChange('mailTemplate', e)}
                        placeholder={Config.lang('Chon_mau_mail_co_san')}
                        value={selectedMailID ?? ''}
                    />
                    <TextInput
                        label={Config.lang('Tieu_de')}
                        placeholder={Config.lang('Tieu_de')}
                        viewType={'outlined'}
                        value={titleMail ?? ''}
                        onChange={(e) => handleChange('titleMail', e)}
                    />
                    <div>
                        <Label>{Config.lang('Noi_dung')}</Label>
                        <Editor
                            value={contentMail}
                            init={{
                                content_style: '.mce-content-body > p > span {font-size:16px}',
                                height: 360,
                                menubar: false,
                                statusbar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount',
                                ],
                                toolbar:
                                    'undo redo | formatselect | fontselect | fontsizeselect | bold italic backcolor ' +
                                    'alignleft aligncenter alignright alignjustify' +
                                    'bullist numlist outdent indent | removeformat',
                                fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
                                toolbar_location: 'bottom',
                            }}
                            blurredEditor
                            onEditorChange={(e) => handleChange('contentMail', e)}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color={'info'}
                        viewType={'filled'}
                        text={Config.lang('Gui')}
                        startIcon={'Send'}
                        disabled={!titleMail || !contentMail || loading}
                        onClick={() => sendMail()}
                    />
                </ModalFooter>
            </Modal>}
        </>
    );
};

SendMailPop.propTypes = {
    open: PropTypes.bool,
    handleOpenPopup: PropTypes.func,
    currentStage: PropTypes.object,
};

SendMailPop.defaultProps = {
    open: false,
    handleOpenPopup: null,
    currentStage: {},
};

export default SendMailPop;
